<template>
  <div>
    <!-- form -->
    <validation-observer
      ref="profilePasswordForm"
      #default="{invalid}"
    >
      <b-form
        class="auth-form mt-2"
        @submit.prevent="onSubmit"
      >
        <!-- current password -->
        <b-form-group
          label-for="current-password"
          label="Current Password"
          label-class="label-class"
        >
          <validation-provider
            #default="{ errors }"
            name="current password"
            vid="current-password"
            rules="required|password|max:255"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="current-password"
                v-model="form.password_current"
                class="form-control-merge"
                :type="password1FieldType"
                :state="errors.length > 0 ? false:null"
                name="current-password"
                placeholder=""
                autocomplete="off"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePassword1Visibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- password -->
        <b-form-group
          label-for="password"
          label="Password"
          label-class="label-class"
        >
          <validation-provider
            #default="{ errors }"
            name="password"
            vid="password"
            rules="required|password|max:255"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="password"
                v-model="form.password"
                class="form-control-merge"
                :type="password2FieldType"
                :state="errors.length > 0 ? false:null"
                name="password"
                placeholder=""
                autocomplete="off"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePassword2Visibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- password_confirmation -->
        <b-form-group
          label-for="password_confirmation"
          label="Password Confirmation"
          label-class="label-class"
        >
          <validation-provider
            #default="{ errors }"
            name="Password Confirmation"
            vid="password_confirmation"
            rules="required|confirmed:password|max:255"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="password-confirmation"
                v-model="form.password_confirmation"
                class="form-control-merge"
                :type="password3FieldType"
                :state="errors.length > 0 ? false:null"
                name="password-confirmation"
                placeholder=""
                autocomplete="off"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePassword3Visibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Action Buttons -->
        <b-row>
          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              variant="primary"
              :disabled="invalid || isSavingActionInProgress"
              type="submit"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BButton, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, confirmed, min, max, password,
} from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validationsfv
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      form: {
        password_current: null,
        password: null,
        password_confirmation: null,
      },
      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
      password3FieldType: 'password',

      // validation
      required,
      confirmed,
      min,
      max,
      password,
      isSavingActionInProgress: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword3Visibility() {
      this.password3FieldType = this.password3FieldType === 'password' ? 'text' : 'password'
    },
    async onSubmit() {
      const valid = await this.$refs.profilePasswordForm.validate()

      if (valid) {
        try {
          this.isSavingActionInProgress = true
          await this.$http.post('/user/profile/change-password', {
            ...this.form,
          })

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Password updated!',
              icon: 'BellIcon',
              variant: 'success',
            },
          }, { timeout: false })

          this.$router.push({ name: 'profile' })
        } catch (error) {
          const { status } = error.response
          if (status === 404) {
            this.$router.push({ name: 'error-404' })
          }
          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          if (status === 422) {
            const { fields } = error.response.data

            this.$refs.profilePasswordForm.setErrors(fields)
          }
        } finally {
          this.isSavingActionInProgress = false
        }
      }
    },
  },
}
</script>
