<template>
  <div>
    <!-- form -->
    <validation-observer
      ref="profileInfoForm"
      #default="{invalid}"
    >

      <b-form
        class="auth-form mt-2"
        @submit.prevent="onSubmit"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-1"
          >
            <div class="d-flex">
              <feather-icon
                icon="UserIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                Personal Information
              </h4>
            </div>
          </b-col>

          <b-col
            cols="12"
            xl="4"
          >
            <!-- first_name -->
            <b-form-group
              label="First Name"
              label-for="first_name"
              label-class="label-class"
            >
              <validation-provider
                #default="{ errors }"
                name="First Name"
                vid="first_name"
                rules="max:255|required"
              >
                <b-form-input
                  id="first_name"
                  v-model="form.first_name"
                  name="first_name"
                  :state="errors.length > 0 ? false:null"
                  placeholder="John"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            xl="4"
          >
            <!-- last_name -->
            <b-form-group
              label="Last Name"
              label-for="last_name"
              label-class="label-class"
            >
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                vid="last_name"
                rules="max:255|required"
              >
                <b-form-input
                  id="last_name"
                  v-model="form.last_name"
                  name="last_name"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Doe"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            xl="4"
          >
            <b-form-group
              label="Type"
              label-for="Type"
              label-class="label-class"
            >
              <b-form-input
                id="type"
                v-model="form.role_name"
                name="type"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            xl="4"
          >
            <!-- phone_number -->
            <b-form-group
              label="Telephone 1"
              label-for="phone_number1"
              label-class="label-class"
            >
              <validation-provider
                #default="{ errors }"
                name="Telephone 1"
                vid="phone_number1"
                rules="required|numeric|min:5|max:15"
              >
                <vue-tel-input
                  id="register-phone_number1"
                  ref="phoneNumber1"
                  v-model="form.phone_number1"
                  class="form-control pl-0"
                  name="register-phone_number1"
                  :preferred-countries="['GB']"
                  :auto-default-country="false"
                  :default-country="phoneISO1"
                  :auto-format="false"
                  :dropdown-options="{
                    showDialCodeInSelection: true,
                    showFlags: true,
                    showDialCodeInList: true
                  }"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            xl="4"
          >
            <b-form-group
              label="Telephone 2"
              label-for="phone_number2"
              label-class="label-class"
            >
              <validation-provider
                #default="{ errors }"
                name="Telephone 2"
                vid="phone_number2"
                rules="numeric|min:5|max:15"
              >
                <vue-tel-input
                  id="register-phone_number2"
                  ref="phoneNumber2"
                  v-model="form.phone_number2"
                  class="form-control pl-0"
                  name="register-phone_number2"
                  :preferred-countries="['GB']"
                  :auto-default-country="false"
                  :default-country="phoneISO2"
                  :auto-format="false"
                  :dropdown-options="{
                    showDialCodeInSelection: true,
                    showFlags: true,
                    showDialCodeInList: true
                  }"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            xl="4"
          >
            <b-form-group
              label="Email"
              label-for="Email"
              label-class="label-class"
            >
              <b-form-input
                id="email"
                v-model="form.email"
                name="email"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mb-1"
          >
            <div class="d-flex my-2">
              <feather-icon
                icon="MapPinIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                Address
              </h4>
            </div>
            <b-form-group>
              <b-form-checkbox
                id="isUserAddressSameAsCompany"
                v-model="isUserAddressSameAsCompany"
                name="checkbox-1"
                :value="1"
                :unchecked-value="0"
              >
                Address the same as billing address of the company
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            xl="4"
          >
            <!-- Country -->
            <b-form-group
              label="Country*"
              label-for="Country"
              label-class="label-class"
            >
              <validation-provider
                #default="{ errors }"
                name="Country"
                vid="Country"
                rules="max:255"
              >

                <v-select
                  v-model="form.country_id"
                  :options="countryList"
                  :reduce="country_id => country_id.id"
                  placeholder="Select country"
                  label="name"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            xl="4"
          >
            <!-- region -->
            <b-form-group
              label="Region/State*"
              label-for="region"
              label-class="label-class"
            >
              <validation-provider
                #default="{ errors }"
                name="Region/State"
                vid="state"
                rules="max:255"
              >
                <b-form-input
                  id="region"
                  v-model="form.state"
                  name="region"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Region/State"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            xl="4"
          >
            <!-- city -->
            <b-form-group
              label="Town/City"
              label-for="city"
              label-class="label-class"
            >
              <validation-provider
                #default="{ errors }"
                name="Town/City"
                vid="city"
                rules="max:255|required"
              >
                <b-form-input
                  id="city"
                  v-model="form.city"
                  name="city"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Town/City"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            xl="4"
          >
            <!-- postcode -->
            <b-form-group
              label="Postcode/Zip Code*"
              label-for="postcode"
              label-class="label-class"
            >
              <validation-provider
                #default="{ errors }"
                name="Postcode/Zip Code"
                vid="postcode"
                rules="required|max:255"
              >
                <b-form-input
                  id="postcode"
                  v-model="form.postcode"
                  name="postcode"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Postcode/Zip Code"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            xl="4"
          >
            <!-- address1 -->
            <b-form-group
              label="Address Line 1*"
              label-for="address1"
              label-class="label-class"
            >
              <validation-provider
                #default="{ errors }"
                name="Address Line 1"
                vid="address1"
                rules="required|max:255"
              >
                <b-form-input
                  id="address1"
                  v-model="form.address1"
                  name="address1"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Address Line 1"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            xl="4"
          >
            <!-- address2 -->
            <b-form-group
              label="Address Line 2*"
              label-for="address2"
              label-class="label-class"
            >
              <validation-provider
                #default="{ errors }"
                name="Address Line 2"
                vid="address2"
                rules="max:255"
              >
                <b-form-input
                  id="address2"
                  v-model="form.address2"
                  name="address2"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Address Line 2"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mb-1"
          >
            <div class="d-flex mt-2">
              <feather-icon
                icon="RssIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                List of subscriptions
              </h4>
            </div>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group>
              <b-form-checkbox
                id="is_technical_bulletin"
                v-model="form.is_technical_bulletin"
                name="checkbox-1"
                :value="1"
                :unchecked-value="0"
              >
                I wish to receive technical related bulletins
              </b-form-checkbox>
            </b-form-group>

            <b-form-group>
              <b-form-checkbox
                id="is_sale_bulletin"
                v-model="form.is_sale_bulletin"
                name="checkbox-1"
                :value="1"
                :unchecked-value="0"
              >
                I wish to receive sales related bulletins
              </b-form-checkbox>
            </b-form-group>

          </b-col>
        </b-row>
        <!-- Action Buttons -->
        <b-row>
          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              variant="primary"
              :disabled="invalid || isSaving"
              type="submit"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton, BFormGroup, BFormInput, BForm, BFormCheckbox, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import {
  required, confirmed, email, min, max, numeric,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const VueTelInput = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input'),
  import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input/dist/vue-tel-input.css'),
// eslint-disable-next-line no-shadow
]).then(([{ VueTelInput }]) => VueTelInput)

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BRow,
    BCol,

    ValidationProvider,
    ValidationObserver,
    vSelect,

    VueTelInput,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    countryList: {
      type: Array,
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    defaultIso: {
      default: null,
    },
  },
  data() {
    return {
      // validation
      required,
      email,
      confirmed,
      isUserAddressSameAsCompany: 0,
      min,
      max,
      numeric,
      isSavingActionInProgress: false,
    }
  },
  setup(props) {
    const form = {
      ...props.userData,
      country_id: props.userData.country ? props.userData.country.id : null,
      is_technical_bulletin: Number(props.userData.is_technical_bulletin),
      is_sale_bulletin: Number(props.userData.is_sale_bulletin),
      role_name: props.userData.role.description,
    }

    delete form.country
    delete form.role
    return {
      form,
      phoneISO1: props.userData.phone_country_iso1,
      phoneISO2: props.userData.phone_country_iso2,
      avatarText,
    }
  },
  watch: {
    isUserAddressSameAsCompany(val) {
      if (val) {
        this.form.address1 = this.form.company.billing_address1
        this.form.address2 = this.form.company.billing_address2
        this.form.country_id = this.form.company.billing_country_id
        this.form.postcode = this.form.company.billing_postcode
        this.form.state = this.form.company.billing_state
        this.form.city = this.form.company.billing_city
      }
    },
  },
  mounted() {
    if (!this.phoneISO1) {
      setTimeout(() => {
        this.$refs.phoneNumber1.choose(this.defaultIso ? this.defaultIso : 'GB')
      }, 500)
    }
    if (!this.phoneISO2) {
      setTimeout(() => {
        this.$refs.phoneNumber2.choose(this.defaultIso ? this.defaultIso : 'GB')
      }, 500)
    }
  },
  methods: {
    async onSubmit() {
      const valid = await this.$refs.profileInfoForm.validate()
      if (valid) {
        this.isSavingActionInProgress = true
        let payload = {
          ...this.form,
        }

        if (this.form.phone_number1) {
          payload = {
            ...payload,
            ...{ phone_number1: this.form.phone_number1 },
            ...{ phone_country_iso1: this.$refs.phoneNumber1.phoneObject.country.iso2 },
            ...{ phone_country_code1: this.$refs.phoneNumber1.phoneObject.country.dialCode },
          }
        } if (this.form.phone_number2) {
          payload = {
            ...payload,
            ...{ phone_country_iso2: this.$refs.phoneNumber2.phoneObject.country.iso2 },
            ...{ phone_country_code2: this.$refs.phoneNumber2.phoneObject.country.dialCode },
            ...{ phone_country_iso1: this.$refs.phoneNumber1.phoneObject.country.iso2 },
            ...{ phone_country_code1: this.$refs.phoneNumber1.phoneObject.country.dialCode },
          }
        } else {
          payload = {
            ...payload,
            ...{ phone_country_iso2: null },
            ...{ phone_country_code2: null },
            ...{ phone_country_iso1: this.$refs.phoneNumber1.phoneObject.country.iso2 },
            ...{ phone_country_code1: this.$refs.phoneNumber1.phoneObject.country.dialCode },
          }
        }
        delete payload.company
        try {
          await this.$http.put('/user/profile/update', {
            ...payload,
          })

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Profile updated!',
              icon: 'BellIcon',
              variant: 'success',
            },
          }, { timeout: false })

          this.$router.push({ name: 'profile' })
        } catch (error) {
          const { status } = error.response
          if (status === 404) {
            this.$router.push({ name: 'error-404' })
          }
          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          if (status === 422) {
            const { fields } = error.response.data

            this.$refs.profileInfoForm.setErrors(fields)
          }
        } finally {
          this.isSavingActionInProgress = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
