var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"profileInfoForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v(" Personal Information ")])],1)]),_c('b-col',{attrs:{"cols":"12","xl":"4"}},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"first_name","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"First Name","vid":"first_name","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_name","name":"first_name","state":errors.length > 0 ? false:null,"placeholder":"John","autocomplete":"off"},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Last Name","label-for":"last_name","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Last Name","vid":"last_name","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last_name","name":"last_name","state":errors.length > 0 ? false:null,"placeholder":"Doe","autocomplete":"off"},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Type","label-for":"Type","label-class":"label-class"}},[_c('b-form-input',{attrs:{"id":"type","name":"type","disabled":""},model:{value:(_vm.form.role_name),callback:function ($$v) {_vm.$set(_vm.form, "role_name", $$v)},expression:"form.role_name"}})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Telephone 1","label-for":"phone_number1","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Telephone 1","vid":"phone_number1","rules":"required|numeric|min:5|max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input',{ref:"phoneNumber1",staticClass:"form-control pl-0",attrs:{"id":"register-phone_number1","name":"register-phone_number1","preferred-countries":['GB'],"auto-default-country":false,"default-country":_vm.phoneISO1,"auto-format":false,"dropdown-options":{
                  showDialCodeInSelection: true,
                  showFlags: true,
                  showDialCodeInList: true
                }},model:{value:(_vm.form.phone_number1),callback:function ($$v) {_vm.$set(_vm.form, "phone_number1", $$v)},expression:"form.phone_number1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Telephone 2","label-for":"phone_number2","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Telephone 2","vid":"phone_number2","rules":"numeric|min:5|max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('vue-tel-input',{ref:"phoneNumber2",staticClass:"form-control pl-0",attrs:{"id":"register-phone_number2","name":"register-phone_number2","preferred-countries":['GB'],"auto-default-country":false,"default-country":_vm.phoneISO2,"auto-format":false,"dropdown-options":{
                  showDialCodeInSelection: true,
                  showFlags: true,
                  showDialCodeInList: true
                }},model:{value:(_vm.form.phone_number2),callback:function ($$v) {_vm.$set(_vm.form, "phone_number2", $$v)},expression:"form.phone_number2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"Email","label-class":"label-class"}},[_c('b-form-input',{attrs:{"id":"email","name":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex my-2"},[_c('feather-icon',{attrs:{"icon":"MapPinIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v(" Address ")])],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"isUserAddressSameAsCompany","name":"checkbox-1","value":1,"unchecked-value":0},model:{value:(_vm.isUserAddressSameAsCompany),callback:function ($$v) {_vm.isUserAddressSameAsCompany=$$v},expression:"isUserAddressSameAsCompany"}},[_vm._v(" Address the same as billing address of the company ")])],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Country*","label-for":"Country","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Country","vid":"Country","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.countryList,"reduce":function (country_id) { return country_id.id; },"placeholder":"Select country","label":"name"},model:{value:(_vm.form.country_id),callback:function ($$v) {_vm.$set(_vm.form, "country_id", $$v)},expression:"form.country_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Region/State*","label-for":"region","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Region/State","vid":"state","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"region","name":"region","state":errors.length > 0 ? false:null,"placeholder":"Region/State","autocomplete":"off"},model:{value:(_vm.form.state),callback:function ($$v) {_vm.$set(_vm.form, "state", $$v)},expression:"form.state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Town/City","label-for":"city","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Town/City","vid":"city","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"city","name":"city","state":errors.length > 0 ? false:null,"placeholder":"Town/City","autocomplete":"off"},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Postcode/Zip Code*","label-for":"postcode","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Postcode/Zip Code","vid":"postcode","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"postcode","name":"postcode","state":errors.length > 0 ? false:null,"placeholder":"Postcode/Zip Code","autocomplete":"off"},model:{value:(_vm.form.postcode),callback:function ($$v) {_vm.$set(_vm.form, "postcode", $$v)},expression:"form.postcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Address Line 1*","label-for":"address1","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Address Line 1","vid":"address1","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address1","name":"address1","state":errors.length > 0 ? false:null,"placeholder":"Address Line 1","autocomplete":"off"},model:{value:(_vm.form.address1),callback:function ($$v) {_vm.$set(_vm.form, "address1", $$v)},expression:"form.address1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Address Line 2*","label-for":"address2","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Address Line 2","vid":"address2","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address2","name":"address2","state":errors.length > 0 ? false:null,"placeholder":"Address Line 2","autocomplete":"off"},model:{value:(_vm.form.address2),callback:function ($$v) {_vm.$set(_vm.form, "address2", $$v)},expression:"form.address2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex mt-2"},[_c('feather-icon',{attrs:{"icon":"RssIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v(" List of subscriptions ")])],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"is_technical_bulletin","name":"checkbox-1","value":1,"unchecked-value":0},model:{value:(_vm.form.is_technical_bulletin),callback:function ($$v) {_vm.$set(_vm.form, "is_technical_bulletin", $$v)},expression:"form.is_technical_bulletin"}},[_vm._v(" I wish to receive technical related bulletins ")])],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"is_sale_bulletin","name":"checkbox-1","value":1,"unchecked-value":0},model:{value:(_vm.form.is_sale_bulletin),callback:function ($$v) {_vm.$set(_vm.form, "is_sale_bulletin", $$v)},expression:"form.is_sale_bulletin"}},[_vm._v(" I wish to receive sales related bulletins ")])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","disabled":invalid || _vm.isSaving,"type":"submit","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'}},[_vm._v(" Save Changes ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }