<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs
      v-if="!loading"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Account</span>
        </template>
        <user-edit-tab-account
          v-if="!loading"
          :user-data="userData"
          :country-list="countries"
          :default-iso="defaultIso"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Change Password</span>
        </template>
        <user-edit-tab-password class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import UserEditTabAccount from './UserEditTabAccount.vue'
import UserEditTabPassword from './UserEditTabPassword.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    UserEditTabAccount,
    UserEditTabPassword,
  },
  data() {
    return {
      userData: null,
      countries: [],
      defaultIso: null,
      loading: false,
    }
  },
  async created() {
    try {
      this.loading = true
      const { data: { data, options } } = await this.$http.get('/user/profile/show')

      this.userData = {
        ...data,
        address1: data.addresses.address1,
        address2: data.addresses.address2,
        city: data.addresses.city,
        state: data.addresses.state,
        postcode: data.addresses.postcode,
        country: data.addresses.country,
      }

      this.countries = options.countries.data

      this.defaultIso = options.countries.defaultIso
      this.loading = false
    } catch (error) {
      console.log('error :>> ', error)
    }
  },
}
</script>

<style>

</style>
